import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Region', route: `${environment.env.api.symfony.baseUrl}/regions`}))
export class RegionEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  libelle: string;

  @EntityProperty({type: String})
  image: string;
}
