import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Entity(new RestEntityDescriptor({name: 'Element', route: `${environment.env.api.symfony.baseUrl}/elements`}))
export class ElementEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  libelle: string;

  @EntityProperty({type: String})
  code: string;

  @EntityProperty({type: String})
  description = '';

  @EntityProperty({type: String})
  icone = '';

  @EntityProperty({type: Number})
  estPanne = 0;

  @EntityProperty({type: String})
  descriptionPanne = '';

  @EntityProperty({type: Number})
  idRegion = 1;

  @EntityProperty({type: Number})
  idElementgenerique: number;

  @EntityProperty({type: Number})
  idElementgeneriqueAssocie: number;

  public static get elementsEnPanne(): Observable<ElementEntity[]> {
    return (<Observable<ElementEntity[]>>ElementEntity.readAll())
      .pipe(map(elements => elements.filter(element => element.estPanne)));
  }

  public static getElementParCode(code: string): Observable<ElementEntity> {
    return (<Observable<ElementEntity[]>>ElementEntity.readAll())
      .pipe(map(elements => elements.find(element => element.code === code)));
  }
}
