import {Pipe, PipeTransform} from '@angular/core';
import {BoiteElementEntity, ElementEntity} from '../entities';

@Pipe({
  name: 'apercu'
})
export class ApercuPipe implements PipeTransform {
  transform(boite: BoiteElementEntity, boitesElements: BoiteElementEntity[], elements: ElementEntity[], nb: number = -1): any {
    const el: ElementEntity[] = [];
    boitesElements.forEach((be: BoiteElementEntity) => {
      if (be.idBoite === boite.id) {
        const elementTrouve = elements.find((e: ElementEntity) => e.id === be.idElement);
        if (elementTrouve) {
          el.push(elementTrouve);
        }
      }
    });
    return nb !== -1 ? el.slice(0, nb) : el;
  }
}
