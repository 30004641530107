import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Plateforme', route: `${environment.env.api.symfony.baseUrl}/plateformes`}))
export class PlateformeEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: Number})
  idIgdb: number;

  @EntityProperty({type: String})
  libelle: string;

  @EntityProperty({type: Number})
  generation: number;

  @EntityProperty({type: String})
  abbreviation: string;

  @EntityProperty({type: Number})
  categorie: PlateformeCategorieEnum;

}

export enum PlateformeCategorieEnum {
  console,
  arcade,
  platform,
  operating_system,
  portable_console,
  computer
}
