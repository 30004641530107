import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Stock', route: `${environment.env.api.symfony.baseUrl}/stocks`}))
export class StockEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: Number})
  idMateriel: number;

  @EntityProperty({type: Date})
  date = new Date();

  @EntityProperty({type: String})
  typeFlux: string;

  @EntityProperty({type: String})
  typeMateriel: string;
}

export enum TypeFlux {
  ENTREE,
  SORTIE
}
