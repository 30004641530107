import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';
import {VilleEntity} from './ville.entity';

@Entity(new RestEntityDescriptor({
  name: 'Adresse',
  route: `${environment.env.api.symfony.baseUrl}/adresse`
}))
export class AdresseEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  nom: string;

  @EntityProperty({type: String})
  numero: string;

  @EntityProperty({type: String})
  indicatif: string;

  @EntityProperty({type: String})
  adresse: string;

  ville: VilleEntity;
}
