import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgFluxifyModule} from 'ng-fluxify';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {SnotifyComponent, SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';

import * as Guards from './guards';
import * as Pipes from './pipes';
import * as Services from './services';

import {environment} from '../../../environments/environment';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from '../authentication/token.interceptor';
import {DialogService, SpinnerService} from '../ui/services';
import {SpinnerComponent} from '../ui/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgFluxifyModule.initialize({enableStoreLogger: !environment.production}),
    AngularFireAuthModule,
    SnotifyModule
  ],
  declarations: [
    Pipes.ApercuPipe,
    Pipes.NbPagesPipe,
    Pipes.OrderByPipe,
    Pipes.PaginationPipe,
    Pipes.GetGenresPipe,
    Pipes.GetPlateformePipe,
    Pipes.GetRegionPipe,
    Pipes.GetSortiesPipe,
    SpinnerComponent
  ],
  exports: [
    SnotifyComponent,
    Pipes.ApercuPipe,
    Pipes.NbPagesPipe,
    Pipes.OrderByPipe,
    Pipes.PaginationPipe,
    Pipes.GetPlateformePipe,
    Pipes.GetGenresPipe,
    Pipes.GetRegionPipe,
    Pipes.GetSortiesPipe,
    SpinnerComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    SpinnerService
  ],
  entryComponents: [
    SpinnerComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
        Guards.CanActivateIfAuthGuard,
        Pipes.ApercuPipe,
        Pipes.OrderByPipe,
        Services.AuthenticationService,
        Services.ConfigurationService
      ]
    };
  }
}

