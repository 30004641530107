import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../services';
import {EmployeEntity} from '../entities/rh/employe.entity';

@Injectable()
export class CanActivateIfAuthGuard implements CanActivate {
  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private af: AngularFireAuth) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.af.authState.pipe(map(auth => {
      this.af.idToken.pipe(map(token => this.authenticationService.idToken = token)).subscribe();
      if (auth === null || auth === undefined) {
        this.router.navigateByUrl('/authentication?callback=' + state.url);
        return false;
      } else {
        this.authenticationService.authState = auth;
        setTimeout(() => {
          this.authenticationService.currentEmploye$ = EmployeEntity.readAll<EmployeEntity>().pipe(map(
            employes => employes.find(employe => employe.email === auth.email)
          ));
        }, 2000);

        return true;
      }
    })).pipe();
  }
}
