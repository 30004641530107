// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: {
    'api': {
      'symfony': {
        'baseUrl': 'https://api.ouestgames.com/api'
      },
      'local': {
        'baseUrl': './assets'
      }
    },
  },
  config: {
    blocList: {
      nombreEl: 10
    },
    list: {
      nombreEl: 5
    }
  },
  firebase: {
    apiKey: "AIzaSyA0t5eVrqSN3OlOPJr9xoLNuPueE62If-E",
    authDomain: "easyanim-1544126413578.firebaseapp.com",
    databaseURL: "https://easyanim-1544126413578.firebaseio.com",
    projectId: "easyanim-1544126413578",
    storageBucket: "easyanim-1544126413578.appspot.com",
    messagingSenderId: "218787261678"
  },
  employes: [
    {
      nom: 'Aimeric / David',
      salaire: 1250,
      charges: 614,
      aide: 0,
      heuresMensuelles: 151,
      heures: [{
        libelle: 'Heures de travail',
        nombre: 0,
        taux: 100
      },
        {
          libelle: 'Heures le dimanche',
          nombre: 0,
          taux: 125
        }]
    }
  ],
  calendarNames: {
    ouestgames: '1) Evénements',
    location: '1) Location',
    exposition: '1) Expositions'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
