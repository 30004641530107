import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Inject, Injectable, Injector} from '@angular/core';
import {take} from 'rxjs/operators';
import {DialogComponent} from '../components';

@Injectable()
export class MobileService {
  isMobile = false;

  constructor() {
    this.isMobile = window.innerWidth <= 640 ? true : false;
  }
}

