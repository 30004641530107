import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Employe', route: `${environment.env.api.symfony.baseUrl}/employes`}))
export class EmployeEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  nom: string;

  @EntityProperty({type: String})
  prenom: string;

  @EntityProperty({type: String})
  email: string;

  @EntityProperty({type: String})
  telephone: string;

  @EntityProperty({type: Boolean})
  estadmin: boolean;

  @EntityProperty({type: Boolean})
  gestiontemps: boolean;

  @EntityProperty({type: Number})
  salairecalculator: number;

  @EntityProperty({type: Number})
  chargescalculator: number;

  @EntityProperty({type: Number})
  aidescalculator: number;

  @EntityProperty({type: Date})
  dateArrivee: Date;

  @EntityProperty({type: Date})
  dateSortie: Date;

  @EntityProperty({type: Date})
  dateNaissance: Date;

  @EntityProperty({type: String})
  contactsecours: string;

  @EntityProperty({type: String})
  telephonecontactsecours: string;
}
