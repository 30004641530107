import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {
  transform<E>(elements: E[], nbElementsParPage: number, indexPageCourante: number = 1): E[] {
    const nombrePages = Math.ceil(elements.length / nbElementsParPage);
    indexPageCourante = indexPageCourante > nombrePages ? nombrePages : indexPageCourante;

    return elements.slice((indexPageCourante - 1) * nbElementsParPage, indexPageCourante * nbElementsParPage);
  }
}
