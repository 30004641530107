import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GenreEntity} from '../entities/genre/genre.entity';
import {GamegenreEntity} from '../entities/game/gamegenre.entity';

@Pipe({
  name: 'getGenres'
})
export class GetGenresPipe implements PipeTransform {
   transform(idGame: number, ggs: GamegenreEntity[]): Observable<GenreEntity[]> {
    return GenreEntity.readAll<GenreEntity>().pipe(map(grs => {
        const filteredGgs = ggs.filter(gg => gg.idGame === idGame);
        return grs.filter(gr => filteredGgs.some(gg => gg.idGenre === gr.id));
      }
    ));
  }
}
