import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';
import {AdresseEntity} from '../adresse';
import {ContactEntity} from './contact.entity';

@Entity(new RestEntityDescriptor({name: 'Client', route: `${environment.env.api.symfony.baseUrl}/clients`}))
export class ClientEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  nom: string;

  @EntityProperty({type: String})
  email: string;

  @EntityProperty({type: String})
  telephone: string;

  adresseFacturation: AdresseEntity;
  contacts: ContactEntity[];
}
