import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';
import {DepartementEntity} from './departement.entity';

@Entity(new RestEntityDescriptor({name: 'Ville', route: `${environment.env.api.symfony.baseUrl}/villes`}))
export class VilleEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  nom: string;

  @EntityProperty({type: String})
  codePostal: string;

  departement: DepartementEntity;
}
