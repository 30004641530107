import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {AngularFireModule} from '@angular/fire';

import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {CanActivateIfAuthGuard, CoreModule} from './modules/core';
import {TokenInterceptor} from './modules/authentication/token.interceptor';
import {CanActivateIfAdminGuard} from './modules/core/guards/can-active-if-admin.guard';

registerLocaleData(localeFr, 'fr');

const routes: Routes = [
  {path: '', redirectTo: 'authentication', pathMatch: 'full'},
  {path: 'authentication', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)},
  {path: 'tools', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule), canActivate: [CanActivateIfAuthGuard]},
  {
    path: 'print',
    loadChildren: () => import('./modules/print/print.module').then(m => m.PrintModule),
    canActivate: [CanActivateIfAuthGuard]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    HttpClientModule,
    RouterModule.forRoot(routes),
    CoreModule.forRoot(),
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr'},
    CanActivateIfAuthGuard,
    CanActivateIfAdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
