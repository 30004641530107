import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Elementtype', route: `${environment.env.api.symfony.baseUrl}/elementstypes`}))
export class ElementtypeEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  libelle: string;

  @EntityProperty({type: String})
  icone = '';

  @EntityProperty({type: Number})
  idElementtypeLie: number;
}
