import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SortieEntity} from '../entities/sorties/sortie.entity';

@Pipe({
  name: 'getSorties'
})
export class GetSortiesPipe implements PipeTransform {
  transform(idGame: number): Observable<SortieEntity[]> {
    return SortieEntity.readAll<SortieEntity>()
      .pipe(map(sts => sts.filter(st => st.idGame === idGame)))
      .pipe(map(sts => sts.sort(this.tri)));
  }

  private tri(a, b) {
    if (a['date'] && b['date']) {
      if (a['date'] > b['date']) {
        return 1;
      }
      if (a['date'] < b['date']) {
        return -1;
      }
    }
    return 0;
  }
}
