import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  config = environment.config;

  constructor() {
    this.chargerConfiguration();
  }

  chargerConfiguration() {
    const cookieConfiguration = localStorage.getItem('config');
    if (!cookieConfiguration || cookieConfiguration.trim() === '') {
      localStorage.setItem('config', JSON.stringify(environment.config));
    } else {
      this.config = JSON.parse(cookieConfiguration);
    }
  }

  modifierConfiguration(attribut: string, valeur: any) {
    this.config[attribut] = valeur;
    localStorage.removeItem('config');
    localStorage.setItem('config', JSON.stringify(this.config));
  }

  modifierConfigurationComplete() {
    localStorage.removeItem('config');
    localStorage.setItem('config', JSON.stringify(this.config));
  }
}
