import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {PlateformeEntity} from '../entities/plateforme/plateforme.entity';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'getPlateforme'
})
export class GetPlateformePipe implements PipeTransform {
  transform(idIgdb: number): Observable<PlateformeEntity> {
    return PlateformeEntity.readAll<PlateformeEntity>().pipe(map(
      pts => pts.find(pt => pt.idIgdb === idIgdb)
    ));
  }
}
