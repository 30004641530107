import {Component} from '@angular/core';
import {Subject} from 'rxjs';
import {DialogConfig} from '../../services';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  resultat: Subject<Function> = new Subject<Function>();
  config: DialogConfig;

  constructor() {

  }

  resolve(fn: Function) {
    this.resultat.next(fn);
  }
}
