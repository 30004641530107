import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nbPages'
})
export class NbPagesPipe implements PipeTransform {
  transform(elements: any[], nbElementsParPage: number): number {
    return Math.ceil(elements.length / nbElementsParPage);
  }
}
