import {Component, Input} from '@angular/core';
import {MobileService} from '../../services';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() titre = 'Sans titre';
  @Input() breadcrumb = [];
  @Input() printRoute;

  constructor() {}
}
