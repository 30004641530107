import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Entity(new RestEntityDescriptor({name: 'Elementgenerique', route: `${environment.env.api.symfony.baseUrl}/elementsgeneriques`}))
export class ElementgeneriqueEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  libelle: string;

  @EntityProperty({type: String})
  icone = '';

  @EntityProperty({type: String})
  description: string;

  @EntityProperty({type: Number})
  idElementtype: number;

  public static get elementsgeneriquesJeux(): Observable<ElementgeneriqueEntity[]> {
    return (<Observable<ElementgeneriqueEntity[]>>ElementgeneriqueEntity.readAll())
      .pipe(map(elementgeneriques => elementgeneriques.filter(elementgenerique => elementgenerique.idElementtype === 8)));
  }

  public static get elementsgeneriquesConsoles(): Observable<ElementgeneriqueEntity[]> {
    return (<Observable<ElementgeneriqueEntity[]>>ElementgeneriqueEntity.readAll())
      .pipe(map(elementgeneriques => elementgeneriques.filter(elementgenerique => elementgenerique.idElementtype === 1)));
  }
}
