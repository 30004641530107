import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Sortie', route: `${environment.env.api.symfony.baseUrl}/sorties`}))
export class SortieEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: Number})
  idIgdb: number;

  @EntityProperty({type: Number})
  categorie: SortieCategorieEnum;

  @EntityProperty({type: Date})
  date: Date;

  @EntityProperty({type: Number})
  mois: number;

  @EntityProperty({type: Number})
  annee: number;

  @EntityProperty({type: Number})
  idGame: number;

  @EntityProperty({type: Number})
  idPlateforme: number;

  @EntityProperty({type: Number})
  region: SortieRegionEnum;

}

export enum SortieCategorieEnum {
  YYYYMMMMDD,
  YYYYMMMM,
  YYYY,
  YYYYQ1,
  YYYYQ2,
  YYYYQ3,
  YYYYQ4,
  TBD,
}

export enum SortieRegionEnum {
  'Europe'= 1,
  'Etats-Unis',
  'Australie',
  'Nouvelle Zélande',
  'Japon',
  'Chine',
  'Asie',
  'Monde',
}
