import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Inject, Injectable, Injector} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {SpinnerComponent} from '../components';
import {Subject} from 'rxjs';

@Injectable()
export class SpinnerService {
  isSpinnerShown = false;
  componentRef: any;
  spinnerSubject$: Subject<boolean> = new Subject();

  constructor(@Inject(DOCUMENT) private document: Document,
              private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
  }

  showSpinner(config: SpinnerConfig) {
    this.appendComponentToBody(SpinnerComponent, config);
    this.document.body.classList.add('modal-open');
    this.isSpinnerShown = true;
  }

  hideSpinner() {
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.document.body.classList.remove('modal-open');
    this.isSpinnerShown = false;
  }

  appendComponentToBody(component: typeof SpinnerComponent, config: SpinnerConfig) {
    // 1. Create a component reference from the component
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    this.componentRef.instance.config = config;

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the body
    document.body.appendChild(domElem);
  }
}

export class SpinnerConfig {
  message: string;
  spinnerSubject?: Subject<boolean>;
}

