import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {ElementEntity} from '../element';
import {map, withLatestFrom} from 'rxjs/operators';
import {BoiteElementEntity} from './boite-element.entity';

@Entity(new RestEntityDescriptor({name: 'Boite', route: `${environment.env.api.symfony.baseUrl}/boites`}))
export class BoiteEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  libelle: string;

  @EntityProperty({type: String})
  code: string;

  private _elements: Observable<ElementEntity[]>;

  public get elements(): Observable<ElementEntity[]> {
    if (!this._elements) {
      this._elements = ElementEntity
        .readAll()
        .pipe(withLatestFrom(
          BoiteElementEntity
            .readAll()
            .pipe(map((boiteElements: BoiteElementEntity[]) => boiteElements.filter(boiteElement => boiteElement.idBoite === this.id))),
          (elements: ElementEntity[], boiteElements: BoiteElementEntity[]) =>
            elements.filter(element => boiteElements.some(boiteElement => boiteElement.idElement === element.id)))
        );
    }

    return this._elements;
  }

  public static getBoiteParCode(code: string): Observable<BoiteEntity> {
    return (<Observable<BoiteEntity[]>>BoiteEntity.readAll())
      .pipe(map(boites => boites.find(boite => boite.code === code)));
  }
}
