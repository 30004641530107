import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Version', route: `${environment.env.api.symfony.baseUrl}/versions`}))
export class VersionEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: Number})
  majeur: number;

  @EntityProperty({type: Number})
  mineur: number;

  @EntityProperty({type: Number})
  rel: number;

  @EntityProperty({type: String})
  notes: string;
}
