import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'BoiteElement', route: `${environment.env.api.symfony.baseUrl}/boites/elements`}))
export class BoiteElementEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: Number})
  idBoite: number;

  @EntityProperty({type: Number})
  idElement: number;
}
