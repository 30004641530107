import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import 'firebase/auth';
import {EmployeEntity} from '../entities/rh/employe.entity';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class AuthenticationService {
  authState: any;
  currentEmploye$: Observable<EmployeEntity>;
  idToken: string;

  constructor(afAuth: AngularFireAuth) {
    const fakeEmploye = new EmployeEntity();
    fakeEmploye.estadmin = false;
    this.currentEmploye$ = new BehaviorSubject(fakeEmploye);
    afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }
}
