import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Inject, Injectable, Injector} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {take} from 'rxjs/operators';
import {DialogComponent} from '../components';

@Injectable()
export class DialogService {
  isDialogShown = false;
  componentRef: any;

  constructor(@Inject(DOCUMENT) private document: Document,
              private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
  }

  async showDialog(config: DialogConfig) {
    this.appendComponentToBody(DialogComponent, config);
    this.document.body.classList.add('modal-open');
    const data: Function = await this.componentRef.instance.resultat.pipe(take(1)).toPromise();
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.document.body.classList.remove('modal-open');
    data();
  }

  appendComponentToBody(component: typeof DialogComponent, config: DialogConfig) {
    // 1. Create a component reference from the component
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    this.componentRef.instance.config = config;

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the body
    document.body.appendChild(domElem);
  }
}

export class DialogConfig {
  titre: string;
  message: string;
  buttons: DialogButton[];
}

export class DialogButton {
  libelle: string;
  function: Function;
  style?: DialogButtonStyle;
}

export enum DialogButtonStyle {
  STYLE_PRIMARY = 'btn-primary',
  STYLE_WARNING = 'btn-warning',
  STYLE_DANGER = 'btn-danger',
  STYLE_SUCCESS = 'btn-success',
  STYLE_DEFAULT = 'btn-secondary'
}
