import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Elementassociation', route: `${environment.env.api.symfony.baseUrl}/elementsassociations`}))
export class ElementassociationEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: Number})
  idElement1: number;

  @EntityProperty({type: Number})
  idElement2: number;

  @EntityProperty({type: String})
  commentaire: string;
}
