import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'Prestation', route: `${environment.env.api.symfony.baseUrl}/prestations`}))
export class PrestationEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  idCalendar: string;

  @EntityProperty({type: String})
  libelle: string;

  @EntityProperty({type: String})
  lieu: string;

  @EntityProperty({type: String})
  description: string;

  @EntityProperty({type: String})
  hebergement: string;

  @EntityProperty({type: String})
  repas: string;

  @EntityProperty({type: String})
  contacts: string;

  @EntityProperty({type: Date})
  dateDebut: Date;

  @EntityProperty({type: Date})
  dateFin: Date;

  toDelete = 1;
}
