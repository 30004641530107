import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';
import {PaysEntity} from './pays.entity';

@Entity(new RestEntityDescriptor({
  name: 'Departement',
  route: `${environment.env.api.symfony.baseUrl}/departements`
}))
export class DepartementEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  nom: string;

  @EntityProperty({type: String})
  numero: string;

  pays: PaysEntity;
}
