import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';

@Entity(new RestEntityDescriptor({name: 'PrestationMateriel', route: `${environment.env.api.symfony.baseUrl}/prestations/materiels`}))
export class PrestationMaterielEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: Number})
  idPrestation: number;

  @EntityProperty({type: Number})
  idMateriel: number;

  @EntityProperty({type: String})
  typeMateriel: string;
}

export enum TypeMateriel {
  ELEMENT,
  BOITE
}
