import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(value: Array<object>, attribute: string, reverse = false): Array<object> {
    if (Array.isArray(value)) {
      value.sort((a: object, b: object) => {
        let propertyA;
        let propertyB;
        if (typeof a[attribute] === 'string') {
          propertyA = (<string>a[attribute]).toLocaleLowerCase();
        } else {
          propertyA = a[attribute];
        }
        if (typeof b[attribute] === 'string') {
          propertyB = <string>b[attribute].toLocaleLowerCase();
        } else {
          propertyB = b[attribute];
        }

        if (propertyA < propertyB) {
          return -1;
        } else if (a[attribute] > b[attribute]) {
          return 1;
        } else {
          return 0;
        }
      });
      if (reverse) {
        return value.reverse();
      } else {
        return value;
      }
    } else {
      return [];
    }
  }
}
