import {AbstractRestEntity, Entity, EntityProperty, RestEntityDescriptor} from 'ng-fluxify';
import {environment} from '../../../../../environments/environment';
import {AdresseEntity} from '../adresse';

@Entity(new RestEntityDescriptor({name: 'Contact', route: `${environment.env.api.symfony.baseUrl}/contacts`}))
export class ContactEntity extends AbstractRestEntity {
  @EntityProperty({type: Number, primary: true})
  id: number;

  @EntityProperty({type: String})
  nom: string;

  @EntityProperty({type: String})
  prenom: string;

  @EntityProperty({type: String})
  email: string;

  @EntityProperty({type: String})
  telephone: string;

  adresse: AdresseEntity;
}
