import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {RegionEntity} from '../entities/region/region.entity';

@Pipe({
  name: 'getRegion'
})
export class GetRegionPipe implements PipeTransform {
  transform(id: number): Observable<RegionEntity> {
    return RegionEntity.read<RegionEntity>(id);
  }
}
